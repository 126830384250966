<template>
  <div>
    <form-wizard
      :subtitle="null"
      :title="null"
      back-button-text="Назад"
      class="mb-3"
      color="#7367F0"
      finish-button-text="Завершить"
      next-button-text="Далее"
      shape="square"
      @on-complete="formSubmitted"
    >
      <tab-content
        :before-change="validationForm"
        title="Общая информация"
      >
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-row>
            <b-col
              class="mb-2"
              cols="12"
            >
              <h5 class="mb-0">
                Общая информация
              </h5>
              <small class="text-muted">
                Введите информацию о правиле
              </small>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Название правила"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Введите название правила"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Camps"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                  label="Кампании"
                  label-for="Camps"
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-modal.modal-xl
                    variant="outline-primary"
                  >
                    <b-spinner
                      v-show="campaignTableLoading"
                      small
                    />
                    Выбрать кампании
                    <template>
                      (выбрано {{ checkedRows.length }})
                    </template>
                  </b-button>

                  <b-modal
                    id="modal-xl"
                    ok-only
                    ok-title="Закрыть"
                    centered
                    size="xl"
                    title="Выбор кампаний"
                  >

                    <section>
                      <b-field grouped>
                        <b-button
                          size="sm"
                          variant="outline-success"
                          @click="addAllCampaign()"
                        >
                          <feather-icon
                            icon="CheckIcon"
                            class="mr-50"
                          />
                          <span class="align-middle">Выбрать все</span>
                        </b-button>
                        <b-button
                          size="sm"
                          style="margin-left: 1.5rem"
                          variant="outline-danger"
                          @click="removeAllCampaign()"
                        >
                          <feather-icon
                            icon="XCircleIcon"
                            class="mr-50"
                          />
                          <span class="align-middle">Очистить список</span>
                        </b-button>
                        <b-button
                          style="margin-left: 1.5rem"
                          size="sm"
                          variant="outline-primary"
                          @click="addFilterBlock()"
                        >
                          <feather-icon
                            :icon="!showFilterBlock ? 'PlusIcon' : 'MinusIcon'"
                            class="mr-50"
                          />
                          <span class="align-middle">Добавить фильтр</span>
                        </b-button>
                        <b-button
                          style="margin-left: 1.5rem"
                          size="sm"
                          variant="outline-primary"
                          @click="addFavCompBlock()"
                        >
                          <feather-icon
                            :icon="!showFavCompBlock ? 'PlusIcon' : 'MinusIcon'"
                            class="mr-50"
                          />
                          <span class="align-middle">Использовать избранные списки</span>
                        </b-button>
                      </b-field>
                      <br>
                      <b-row v-show="showFavCompBlock">
                        <b-col md="3">
                          <b-form-group
                            label="Избранные списки кампаний"
                            label-for="item-name-12223"
                          >
                            <v-select
                              id="item-name-12223"
                              v-model="favCompFilter"
                              :options="favComps"
                              label="text"
                              placeholder="Выберите избранный список"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row v-show="showFilterBlock">
                        <b-col md="2">
                          <b-form-group
                            label="Выберите период"
                            label-for="item-name-123"
                          >
                            <flat-pickr
                              v-model="rangeDataFilter"
                              :config="dataPickerConfig"
                              class="form-control flat-picker bg-transparent"
                              placeholder="YYYY-MM-DD"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="2">
                          <b-form-group
                            label="Показатель"
                            label-for="item-name-1222"
                          >
                            <v-select
                              id="item-name-1222"
                              v-model="trafficFilter"
                              :options="optionsTraffic"
                              label="text"
                              placeholder="Выберите показатель"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="2">
                          <b-form-group
                            label="Условие"
                            label-for="cost-122"
                          >
                            <v-select
                              v-model="conditionFilter"
                              :options="optionsWithProperties"
                              label="text"
                              placeholder="Выберите условие"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="1">
                          <b-form-group
                            label="Значение"
                            label-for="quantity-filter"
                          >
                            <b-form-input
                              id="quantity-filter"
                              v-model="valueFilter"
                              type="number"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="2">
                          <b-form-group
                            label=""
                            label-for="quantity-122"
                          >
                            <b-button
                              style="margin-top: 20px;"
                              :disabled="!(rangeDataFilter != null && valueFilter != null && conditionFilter != null && trafficFilter != null)"
                              variant="outline-primary"
                              @click="submitFilter()"
                            >
                              <span class="align-middle">Применить</span>
                            </b-button>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row
                        class="center"
                      >
                        <b-col md="4">
                          <p><i>Данные по умолчанию загружаются за последний месяц</i></p>
                        </b-col>
                        <b-col md="2">
                          Кол-во кампаний <b>{{ data.length }} кампаний(и)</b>
                        </b-col>
                        <b-col
                          v-show="checkedRows.length !== 0"
                          md="2"
                        >
                          Выбрано <b>{{ checkedRows.length }} кампаний(и)</b>
                        </b-col>
                      </b-row>
                      <br>
                      <b-table
                        :data="data"
                        :columns="columns"
                        :checked-rows.sync="checkedRows"
                        :paginated="true"
                        :loading="campaignTableLoading"
                        checkable
                        :checkbox-position="checkboxPosition"
                      >
                        <template v-for="column in columns">
                          <b-table-column
                            :key="column.campaign"
                            v-bind="column"
                            sortable
                          >
                            <template
                              v-if="column.searchable && !column.numeric"
                              #searchable="props"
                            >
                              <b-input
                                v-model="props.filters[props.column.field]"
                                placeholder="Поиск..."
                                icon="magnify"
                                size="is-small"
                              />
                            </template>
                            <template v-slot="props">
                              {{ columnValueFormat(props.row[column.field]) }}
                            </template>
                          </b-table-column>
                        </template>
                      </b-table>
                    </section>
                  </b-modal>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <tab-content

        :before-change="validationFormInfo"
        title="Частота использования"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>
            <b-col
              class="mb-2"
              cols="12"
            >
              <h5 class="mb-0">
                Применение правила
              </h5>
              <small class="text-muted">Выберите частоту применения правил.</small>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Frequency"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                  label="Частота"
                  label-for="Frequency"
                >
                  <v-select
                    id="country"
                    v-model="selectedFrequency"
                    :options="frequencyName"
                    :searchable="false"
                    :selectable="option => ! option.value.includes('select_value')"
                    label="text"
                    placeholder="Выберите частоту"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                  <div v-if="isFreqShow">
                    <label style="margin-top: 15px;">Выберите период запуска</label>
                    <flat-pickr
                      v-model="rangeFreqData"
                      :config="dataPickerConfig"
                      class="form-control flat-picker bg-transparent border-0 shadow-none"
                      placeholder="YYYY-MM-DD"
                    />
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <tab-content
        :before-change="validationFormAddress"
        title="Условия использования"
      >
        <validation-observer
          ref="addressRules"
          tag="form"
        >
          <b-row>
            <b-col
              class="mb-2"
              cols="12"
            >
              <h5 class="mb-0">
                Условия использования
              </h5>
              <small class="text-muted">Добавьте необходимые условия.</small>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label-for="address"
              >
                <div>
                  <div>
                    <b-form
                      ref="form"
                      class="repeater-form "
                      @submit.prevent="repeateAgain"
                    >
                      <b-row
                        v-for="(item, index) in items"
                        :id="item.id"
                        :key="item.id"
                        ref="row"
                      >
                        <b-col
                          v-if="index > 0"
                          cols="12"
                          style="text-align: center; height: 20px; margin-bottom: 35px"
                        >
                          <b-badge variant="success">
                            <span>ИЛИ</span>
                          </b-badge>
                        </b-col>
                        <!--   <b-col md="3">
                          <b-form-group
                            label="Статистика за период"
                            label-for="cost"
                          >
                            <flat-pickr
                              v-model="item.rangeData"
                              style="width: 100%!important;"
                              :config="dataPickerConfig"
                              class="form-control flat-picker bg-transparent"
                              placeholder="YYYY-MM-DD"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="3">
                          <b-form-group
                            label="Показатель"
                            label-for="item-name"
                          >
                            <v-select
                              id="item-name"
                              v-model="item.traffic"
                              :options="optionsTraffic"
                              label="text"
                              placeholder="Выберите показатель"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="2">
                          <b-form-group
                            label="Условие"
                            label-for="cost"
                          >
                            <v-select
                              id="cost"
                              v-model="item.cost"
                              :options="optionsProperties"
                              label="text"
                              placeholder="Выберите"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="2">
                          <b-form-group
                            label="Значение"
                            label-for="quantity"
                          >
                            <b-form-input
                              id="quantity"
                              v-model="item.val"
                              :placeholder="percentagePlaceHolder(item.traffic)"
                              type="number"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          class="mb-50"
                          lg="2"
                          md="3"
                        >
                          <b-button
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            class="mt-0 mt-md-2"
                            variant="outline-danger"
                            @click="removeItem(index)"
                          >
                            <feather-icon
                              class="mr-25"
                              icon="XIcon"
                            />
                            <span>Удалить</span>
                          </b-button>
                        </b-col>-->

                        <b-col cols="12">
                          <b-row
                            v-for="(subItem, subIndex) in item.subItems"
                            :id="subItem.id"
                            :key="subIndex.id"
                            ref="subRow"
                          >
                            <b-col
                              v-if="subIndex > 0"
                              cols="12"
                              style="height: 25px;margin-bottom: 5px;"
                            >
                              <b-badge variant="success">
                                <span>И</span>
                              </b-badge>
                            </b-col>
                            <b-col md="3">
                              <b-form-group
                                label="Статистика за период"
                                label-for="cost"
                              >
                                <flat-pickr
                                  v-model="subItem.rangeData"
                                  style="width: 100%!important;"
                                  :config="dataPickerConfig"
                                  class="form-control flat-picker bg-transparent"
                                  placeholder="YYYY-MM-DD"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col md="3">
                              <b-form-group
                                label="Показатель"
                                label-for="item-name"
                              >
                                <v-select
                                  id="item-name"
                                  v-model="subItem.traffic"
                                  :options="optionsTraffic"
                                  :searchable="false"
                                  label="text"
                                  placeholder="Выберите показатель"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col md="2">
                              <b-form-group
                                label="Условие"
                                label-for="cost"
                              >
                                <v-select
                                  id="cost"
                                  v-model="subItem.cost"
                                  :options="optionsProperties"
                                  :searchable="false"
                                  label="text"
                                  placeholder="Выберите"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col md="2">
                              <b-form-group
                                label="Значение"
                                label-for="quantity"
                              >
                                <b-form-input
                                  id="quantity"
                                  v-model="subItem.val"
                                  :placeholder="percentagePlaceHolder(subItem.traffic)"
                                  type="number"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col
                              class="mb-50"
                              lg="2"
                              md="3"
                            >
                              <b-button
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                class="mt-0 mt-md-2"
                                variant="outline-danger"
                                @click="removeItem(index, subIndex)"
                              >
                                <feather-icon
                                  class="mr-25"
                                  icon="XIcon"
                                />
                                <span>Удалить</span>
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col cols="12">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            @click="repeatAddCondition($refs.row[index].id)"
                          >
                            <feather-icon
                              class="mr-25"
                              icon="PlusIcon"
                            />
                            <span>Добавить И</span>
                          </b-button>
                        </b-col>
                        <b-col cols="12">
                          <div style="height: 10px" />
                        </b-col>
                      </b-row>
                    </b-form>
                  </div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="repeateAgain"
                  >
                    <feather-icon
                      class="mr-25"
                      icon="PlusIcon"
                    />
                    <span>Добавить ИЛИ</span>
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <tab-content
        :before-change="validationFormSocial"
        title="Действия"
      >
        <validation-observer
          ref="actionRules"
          tag="form"
        >
          <b-row>
            <b-col
              class="mb-2"
              cols="12"
            >
              <h5 class="mb-0">
                Общая информация
              </h5>
              <small class="text-muted">
                Введите информацию о правиле
              </small>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="action"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                  label="Действие"
                  label-for="action"
                >

                  <v-select
                    id="action"
                    v-model="action"
                    :options="actionNames"
                    :selectable="option => !option.value.includes('select_value')"
                    label="text"
                    placeholder="Выберите действие"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-show="action != null ? action.value === 'set-value' : false"
              md="6"
            >
              <b-form-group
                label="Значение ставки"
                label-for="req"
              >
                <validation-provider
                  #default="{ errors }"
                  name="req"
                  rules="required"
                >
                  <b-form-input
                    id="req"
                    v-model="valueBid"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Введите значение ставки"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-show="action != null ? action.value === 'percent' : false"
              md="6"
            >
              <b-form-group
                label="Процент изменения"
                label-for="percent"
              >
                <validation-provider
                  #default="{ errors }"
                  name="percent"
                  rules="required"
                >
                  <b-form-input
                    id="percent"
                    v-model="valueBid"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Введите процент"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-show="action != null ? action.value === 'percent' : false"
              md="6"
            >
              <b-form-group
                :label="(valueBid > 0 ? 'Максимальное' : 'Минимальное') + ' значение ставки'"
                label-for="maxBid"
              >
                <validation-provider
                  #default="{ errors }"
                  name="maxBid"
                >
                  <b-form-input
                    id="maxBid"
                    v-model="maxValueBid"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Введите значение"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <tab-content
        title="Итог"
      >
        <validation-observer
          ref="resRules"
          tag="form"
        >
          <b-row>
            <b-col
              class="mb-2"
              cols="12"
            >
              <h5 class="mb-0">
                Общая информация
              </h5>
              <small class="text-muted">
                Введите информацию о правиле
              </small>
            </b-col>
            <b-col cols="12">
              <b-list-group>
                <b-list-group-item class="flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">
                      Название
                    </h5>
                  </div>
                  <b-card-text>
                    {{ name }}
                  </b-card-text>
                </b-list-group-item>

                <b-list-group-item class="flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">
                      Аккаунты
                    </h5>
                  </div>
                  <b-card-text>
                    Яндекс Директ: rusbuketads
                  </b-card-text>
                </b-list-group-item>

                <b-list-group-item class="flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">
                      Кампании
                    </h5>
                  </div>
                  <b-card-text>
                    {{
                      checkedRows.map(a => a.campaign)
                        .join(',')
                    }}
                  </b-card-text>
                </b-list-group-item>

                <b-list-group-item class="flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">
                      Частота применения:
                    </h5>
                  </div>
                  <b-card-text>
                    Один раз в день
                  </b-card-text>
                </b-list-group-item>

                <!--<b-list-group-item class="flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">
                      Условия
                    </h5>
                  </div>
                  <b-card-text>
                    <div
                        v-for="(item, i) in items"
                        :key="item.id + '_' + i"
                    >
                      <h6 class="mb-1">
                        Условие № {{ i + 1 }}
                      </h6>
                      <div
                          v-for="(subItem, subIndex) in item.subItems"
                          :key="item.id + '_' + subIndex"
                          class="mb-1"
                      >
                        Дата: {{ subItem.rangeData }} <br>
                        Условие по статистике: {{ subItem.traffic.text }} {{ subItem.cost.text }} {{ subItem.val }}
                        <br>
                        <b-badge
                            v-show="item.subItems.length !== subIndex + 1"
                            class="mt-1"
                            variant="success"
                        >
                          <span>И</span>
                        </b-badge>
                      </div>
                    </div>
                  </b-card-text>
                </b-list-group-item>-->
                <!--  <b-list-group-item v-show="action != null" class="flex-column align-items-start">
                    <div class="d-flex w-100 justify-content-between">
                      <h5 class="mb-1">
                        Действия
                      </h5>
                    </div>
                    <b-card-text>
                      <div v-if="action.value = 'percent'">
                        Изменение ставки на процент
                      </div>
                      <div v-else>
                        Установить определенное значение ставки
                      </div>
                    </b-card-text>
                  </b-list-group-item>-->

                <!--     <b-list-group-item class="flex-column align-items-start mb-2">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">
                      Ставка
                    </h5>
                  </div>
                  <b-card-text>
                    Макс/мин ставка: {{ maxValueBid }} <br>
                    <div v-if="action.value !== 'percent'">
                      Ставка: {{ valueBid }}
                    </div>
                    <div v-else>
                      Процент: {{ valueBid }}% <br>
                    </div>
                  </b-card-text>
                </b-list-group-item>-->
              </b-list-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import Vue from 'vue'
import vSelect from 'vue-select'
import Multiselect from 'vue-multiselect'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  Checkbox,
  Field, Input,
  // eslint-disable-next-line no-unused-vars
  Table,
} from 'buefy'
import {
  BFormTags, BInputGroup, BListGroupItem,
  BInputGroupAppend, BButton, BCardText,
  BCol, BSpinner, BForm,
  BFormGroup, BFormInput, BListGroup,
  BFormInvalidFeedback, BRow, BBadge,
} from 'bootstrap-vue'
import { email, required } from '@validations'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { Russian } from 'flatpickr/dist/l10n/ru'
import flatPickr from 'vue-flatpickr-component'

// to-do: refactoring
Vue.use(Field)
Vue.use(Input)
Vue.use(Table)
Vue.use(Checkbox)

const dateNow = new Date()
const dateNowPrev = new Date(new Date().setMonth(new Date().getMonth() - 1))

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Multiselect,
    FormWizard,
    BBadge,
    TabContent,
    BCardText,
    BListGroupItem,
    BFormTags,
    BListGroup,
    BInputGroup,
    BInputGroupAppend,
    BRow,
    BSpinner,
    BForm,
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    flatPickr,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    ruleId: [String, Number],
  },
  data() {
    return {
      data: [],
      rangeFreqData: [],
      changedRangeData: [],
      showFilterBlock: true,
      showFavCompBlock: false,
      checkboxPosition: 'left',
      checkedRows: [],
      isFreqShow: false,
      campaignTableLoading: false,
      rangeDataFilter: null,
      trafficFilter: null,
      favCompFilter: null,
      favCompFilterName: null,
      conditionFilter: null,
      valueFilter: null,
      columns: [
        {
          field: 'campaign',
          label: 'Кампании',
          searchable: true,
        },
        {
          field: 'shows',
          label: 'Показы',
        },
        {
          field: 'clicks',
          label: 'Клики',
        },
        {
          field: 'consumption',
          label: 'Расходы',
        },
        {
          field: 'ctr',
          label: 'Ctr',
        },
        {
          field: 'avgCpc',
          label: 'Средняя стоимость клика',
        },
        {
          field: 'profit',
          label: 'Выручка',
        },
        {
          field: 'numberOrders',
          label: 'Кол-во заказов',
        },
        {
          field: 'cost',
          label: 'Стоимость заказов',
        },
        {
          field: 'cr',
          label: 'Cr',
        },
        {
          field: 'roi',
          label: 'Roi',
        },
        {
          field: 'analyticRevenue',
          label: 'Доход (аналитика)',
        },
        {
          field: 'analyticGoals',
          label: 'Кол-во покупок (аналитика)',
        },
      ],
      value: [],
      percentValues: ['roi', 'ctr', 'cr'],
      rubValues: ['cost', 'profit', 'avgCpc', 'consumption'],
      valueBid: null,
      maxValueBid: null,
      rangeData: [dateNowPrev, dateNow],
      results: [
        {
          name: 'Название',
          description: 'Test 1',
        },
        {
          name: 'Аккаунты',
          description: 'Яндекс Директ: rusbuketads',
        },
        {
          name: 'Частота применения',
          description: 'Test 3',
        },
      ],
      dataPickerConfig: {
        mode: 'range',
        locale: Russian,
        dateFormat: 'd.m.Y',
      },
      options: [],
      favComps: [],
      favCompsData: [],
      optionsTraffic: [
        {
          value: 'clicks',
          text: 'Клики',
        },
        {
          value: 'shows',
          text: 'Показы',
        },
        {
          value: 'ctr',
          text: 'CTR',
        },
        {
          value: 'cr',
          text: 'CR',
        },
        {
          value: 'cost',
          text: 'Стоимость заказа',
        },
        {
          value: 'numberOrders',
          text: 'Кол-во заказов',
        },
        {
          value: 'consumption',
          text: 'Расходы',
        },
        {
          value: 'roi',
          text: 'Roi',
        },
        {
          value: 'profit',
          text: 'Выручка',
        },
      ],
      optionsProperties: [
        {
          value: 'more',
          text: '>',
        },
        {
          value: 'more-equal',
          text: '≥',
        },
        {
          value: 'equal',
          text: '=',
        },
        {
          value: 'less',
          text: '<',
        },
        {
          value: 'less-equal',
          text: '≤',
        },
      ],
      optionsWithProperties: [
        {
          value: 'more',
          text: '>',
        },
        {
          value: 'equal',
          text: '=',
        },
        {
          value: 'less',
          text: '<',
        },
      ],
      items: [],
      nextTodoId: 1,
      selectedFrequency: {
        value: 'once',
        text: 'Один раз',
      },
      selectedLanguage: '',
      name: '',
      emailValue: '',
      PasswordValue: '',
      passwordCon: '',
      first_name: '',
      last_name: '',
      address: '',
      landMark: '',
      pincode: '',
      twitterUrl: '',
      facebookUrl: '',
      googleUrl: '',
      linkedinUrl: '',
      city: '',
      required,
      email,
      frequencyName: [
        {
          value: 'once',
          text: 'Один раз',
        },
        {
          value: 'every-hour',
          text: 'Каждый час',
        },
        {
          value: 'every-two-hour',
          text: 'Каждые два часа',
        },
      ],
      action: null,
      actionNames: [
        {
          value: 'set-value',
          text: 'Установить определенное значение ставки',
        },
        {
          value: 'percent',
          text: 'Изменение ставки на процент',
        },
      ],
    }
  },
  computed: {
    activeProjectId() {
      return this.$store.state.project.activeProjectId
    },
  },
  watch: {
    activeProjectId(oldState, newState) {
      this.loadCamps()
    },
    selectedFrequency(oldState, newState) {
      this.isFreqShow = !!['every-hour', 'every-two-hour'].includes(this.selectedFrequency.value)
    },
    favCompFilter(oldState, newState) {
      if (this.favCompFilter != null && oldState != null && oldState !== newState) {
        const favCompFilterName = oldState.text
        const findedFavCamp = this.favCompsData.find(c => c.name === favCompFilterName)

        const parsedCampaigns = JSON.parse(findedFavCamp.campaigns.replace(/'/g, '"'))
        this.checkedRows = this.data.filter(stat => parsedCampaigns.includes(stat.campaign))
      } else {
        this.checkedRows = []
      }
    },
  },
  mounted() {
    this.loadCamps()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    loadCamps() {
      if (this.activeProjectId != null) {
        this.campaignTableLoading = true
        const obj = {
          projectId: this.activeProjectId,
        }

        this.$http.get(`/favcomps?projectId=${this.activeProjectId}`)
          .then(response => {
            const dataForParse = response.data
            this.favCompsData = dataForParse
            let indexCampaign = 0
            dataForParse.map(c => c.name)
              .forEach(campaignText => {
                indexCampaign += 1
                this.favComps.push({
                  value: indexCampaign,
                  text: campaignText,
                })
              })
          })

        this.$http.post('/adrules/campings', obj)
        // eslint-disable-next-line no-unused-vars
          .then(response => {
            const dataStatsForParse = response.data

            const parsedTempData = []
            let i = 0
            dataStatsForParse.forEach(item => {
              const stat = {
                id: 0,
                campaign: item.campaign,
                shows: this.columnValueFormat(item.shows),
                clicks: this.columnValueFormat(item.clicks),
                consumption: this.columnValueFormat(item.consumption),
                avgCpc: this.columnValueFormat(item.avgCpc),
                ctr: this.columnValueFormat(item.ctr),
                marginProfit: this.columnValueFormat(item.marginProfit),
                profit: this.columnValueFormat(item.profit),
                cost: this.columnValueFormat(item.cost),
                cr: this.columnValueFormat(item.cr),
                numberOrders: this.columnValueFormat(item.numberOrders),
                analyticRevenue: this.columnValueFormat(item.analyticRevenue),
                analyticGoals: this.columnValueFormat(item.analyticGoals),
                avgTrafficVolume: this.columnValueFormat(item.avgTrafficVolume),
                analyticMarginProfit: this.columnValueFormat(item.analyticMarginProfit),
              }

              parsedTempData.push(stat)
              i += 1
            })

            this.data = parsedTempData
            this.campaignTableLoading = false

            if (this.ruleId !== 'new') {
              this.$http.get(`/adrules/details/${this.ruleId}`)
                .then(response => {
                  const dataForParse = response.data
                  //  console.log(dataForParse)

                  this.checkedRows = this.data
                    .filter(stat => JSON.parse(dataForParse.campaigns)
                      .includes(stat.campaign))

                  this.data = this.data
                    .filter(stat => !JSON.parse(dataForParse.campaigns)
                      .includes(stat.campaign))

                  this.data = parseData

                  this.checkedRows.forEach(item => {
                    this.data.unshift(item)
                  })

                  this.name = dataForParse.name
                  this.selectedFrequency = {
                    value: 'once',
                    text: 'Один раз',
                  }

                  this.items = []
                  this.items = JSON.parse(dataForParse.condition)
                  this.maxValueBid = dataForParse.maxBid
                  this.action = this.actionNames.find(c => c.value === dataForParse.typeBid)
                  this.valueBid = Number(dataForParse.value)

                  this.initTrHeight()
                })
            }
          })

        this.initTrHeight()
      }
    },
    columnValueFormat(val) {
      if (val === null || val === '-' || val === undefined || isNaN(val)) {
        return '-'
      }

      if (val === 0) {
        return 0
      }

      if (Math.round(+val) !== +val) {
        return (val.toFixed(2)).toLocaleString()
      }

      return (+val).toLocaleString()
    },
    percentagePlaceHolder(itemTraffic) {
      if (itemTraffic != null) {
        if (this.percentValues.includes(itemTraffic.value)) {
          return '%'
        }

        if (this.rubValues.includes(itemTraffic.value)) {
          return 'руб.'
        }
        return ''
      }
      return ''
    },
    submitFilter() {
      this.checkedRows = []
      if (this.activeProjectId != null) {
        this.campaignTableLoading = true
        const obj = {
          rangeData: this.rangeDataFilter,
          traffic: this.trafficFilter.value.toString(),
          condition: this.conditionFilter.text.toString(),
          value: this.valueFilter.toString(),
          projectId: this.activeProjectId,
        }
        this.$http.post('/adrules/campings', obj)
        // eslint-disable-next-line no-unused-vars
          .then(response => {
            const dataForParse = response.data

            const parseData = []
            let i = 0

            dataForParse.forEach(item => {
              const stat = {
                id: 0,
                campaign: item.campaign,
                shows: this.columnValueFormat(item.shows),
                clicks: this.columnValueFormat(item.clicks),
                consumption: this.columnValueFormat(item.consumption),
                avgCpc: this.columnValueFormat(item.avgCpc),
                ctr: this.columnValueFormat(item.ctr),
                marginProfit: this.columnValueFormat(item.marginProfit),
                profit: this.columnValueFormat(item.profit),
                cost: this.columnValueFormat(item.cost),
                cr: this.columnValueFormat(item.cr),
                numberOrders: this.columnValueFormat(item.numberOrders),
                analyticRevenue: this.columnValueFormat(item.analyticRevenue),
                analyticGoals: this.columnValueFormat(item.analyticGoals),
                avgTrafficVolume: this.columnValueFormat(item.avgTrafficVolume),
                analyticMarginProfit: this.columnValueFormat(item.analyticMarginProfit),
              }

              parseData.push(stat)
              i += 1
            })

            this.data = parseData
            this.campaignTableLoading = false
          })
      }
    },
    addFilterBlock() {
      this.showFilterBlock = !this.showFilterBlock
    },
    addFavCompBlock() {
      this.showFavCompBlock = !this.showFavCompBlock
    },
    addAllCampaign() {
      this.checkedRows = this.data
    },
    removeAllCampaign() {
      this.checkedRows = []
    },
    repeateAgain() {
      //  console.log(this.$refs, 'this.$refs')

      let indexBlock = 0
      if (this.items.length !== 0) {
        indexBlock = this.items[this.items.length - 1].id
      } else {
        indexBlock = this.nextTodoId
      }

      this.items.push({
        id: this.nextTodoId += indexBlock,
        subItems: [],
      })

      this.repeatAddCondition(this.nextTodoId)

      this.$nextTick(() => {
        //  console.log(this.$refs.row[this.$refs.row.length - 1].offsetHeight, 'this.$refs.row[0].offsetHeight repeateAgain')
        this.trAddHeight(this.$refs.row[this.$refs.row.length - 1].offsetHeight)
      })
    },
    repeatAddCondition(rowElemId) {
      let indexBlock = 0
      if (this.items.length !== 0) {
        indexBlock = this.items[this.items.length - 1].id
      } else {
        indexBlock = this.nextTodoId
      }
      const searchItem = this.items.find(c => c.id == rowElemId)
      // console.log(searchItem, 'searchItem')
      let subRowId = 0
      subRowId = indexBlock + searchItem.subItems.length
      searchItem.subItems.push({
        id: `subrow-${subRowId}`,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.subRow[0].offsetHeight)
      })
    },
    removeItem(index, subIndex) {
      this.items[index].subItems.splice(subIndex, 1)
      if (this.items[index].subItems.length === 0) {
        this.items.splice(index, 1)
      }
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      //  console.log(this.trSetHeight, 'initTrHeight trSetHeight')
      let blockHeight = 0
      this.items.forEach(() => {
        blockHeight += 100
      })

      this.$nextTick(() => {
        // console.log(this.$refs, 'this.$refs.form')
        this.trSetHeight(blockHeight)
      })
    },
    formSubmitted() {
      const parsedCamps = this.checkedRows.map(a => a.campaign)

      let frequencyConditionRangeVal = null
      if (this.selectedFrequency.value !== 'once') {
        frequencyConditionRangeVal = this.rangeFreqData.toString()
      }

      const obj = {
        name: this.name,
        campaigns: JSON.stringify(parsedCamps),
        frequency: this.selectedFrequency.text.toString(),
        // eslint-disable-next-line eqeqeq
        frequencyConditionRange: frequencyConditionRangeVal,
        condition: JSON.stringify(this.items),
        maxBid: this.maxValueBid,
        typeBid: this.action.value,
        projectId: this.activeProjectId,
        value: this.valueBid.toString(),
      }
      console.log(obj, 'obj')
      if (this.ruleId === 'new') {
        this.$http.post('/adrules/create', obj)
        // eslint-disable-next-line no-unused-vars
          .then(res => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Правило создано',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'rules' })
          })
      } else {
        obj.id = this.ruleId
        console.log(obj, 'obj')
        this.$http.post('/adrules/edit', obj)
        // eslint-disable-next-line no-unused-vars
          .then(res => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Правило отредактировано',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'rules' })
          })
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate()
          .then(success => {
            if (success) {
              this.results[0].description = this.name
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate()
          .then(success => {
            if (success) {
              this.results[2].description = this.selectedFrequency.text
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormSocial() {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        resolve(true)
      })
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>
<style>
.hidden_header {
  display: none;
}
</style>
<style lang="scss" scoped>
.repeater-form {
  transition: .35s height;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
