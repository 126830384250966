<template>
  <section id="add-rule">
    <b-row class="match-height">
      <b-col cols="12">
        <form-wizard-number :rule-id="ruleId" />
      </b-col>
    </b-row>
  </section>
</template>

<script>

import {
  BRow, BCol, BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle, BOverlay,
} from 'bootstrap-vue'
import FormWizardNumber from '@/views/forms/form-wizard/FormWizardNumber'

export default {
  components: {
    FormWizardNumber,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BOverlay,
    BRow,
    BCol,
  },
  props: {
    ruleId: [String, Number],
  },
  data() {
    return {
    }
  },
  computed: {
  },
  created() {
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
